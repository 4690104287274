<script>
import Vue from "vue";
import api from '@/helpers/apirest/api';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import customerShow from "@/views/pages/customers/show/show.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import Status from "@/helpers/general/status";
import AddButton from "@/components/buttons/add-button.vue";
import CardApplicationForm from "@/views/pages/card-applications/forms/card-application-form.vue";
import YouthCardPaymentDocumentButton from '@/components/buttons/youth-card-payment-document-button.vue';
import YouthCardButton from '@/components/buttons/youth-card-button.vue';
import TemplatePrint from "@/views/pages/templates/print/template-print.vue";
import QlkImport from '@/components/imports/qlk-import.vue';
import SelectField from '@/components/forms/select-field.vue';
import CardApplicationActions from "@/components/buttons/card-application-actions.vue";
import CardApplicationRevisedButton from "@/components/buttons/card-application-revised-button.vue";
import YouthCardShippingPrintButton from "@/components/buttons/youth-card-shipping-print-button.vue";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import DateRangeField from '@/components/forms/date-range-field.vue';

/**
 * Customers Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        customerShow,
        CustomTable,
        AddButton,
        CardApplicationForm,
        TemplatePrint,
        YouthCardPaymentDocumentButton,
        YouthCardButton,
        QlkImport,
        SelectField,
        CardApplicationActions,
        SelectAutocompleteField,
        DateRangeField,
        CardApplicationRevisedButton,
        YouthCardShippingPrintButton
    },
    data() {
        return {
            formModalId: 'card-application-form-modal',
            showModalId: 'card-application-show-modal',
            QlkImportModalId: 'card-application-qlk-import-modal',
            templatePrintModalId: 'card-applications-template-print-modal',
            pageHeader: {
                title: "Solicitudes de carné",
                breadcrumbs: [
                    {
                        text: "Solicitudes de carné",
                        href: "/card-applications",
                        active: true
                    }
                ],
            },
            customer_status: Status.getList('customers'),
            card_types: Status.getList('youth_card_types'),
            operation_types: Status.getList('card_application_operation_types'),
            card_application_paid_status: Status.getList('card_application_paid_status'),
            card_application_statuses: Status.getList('card_application_statuses'),
            shipping_types: Status.getList('shipping_types'),
            revised_status: Status.getList('revised_status'),
            years_old_list: Status.getList('years_old_list'),
            genders: Status.getList('genders'),
            table: {
                url: '/card-applications',
                params: {
                    'with_trashed': 1,
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "created_at", label: "Fecha de solicitud", sortable: false },
                    { key: "customer.identification", label: "Identificación", sortable: false },
                    { key: "customer.name", label: "Nombre", sortable: false },
                    { key: "customer.latest_youth_card.code", label: "Nº de Carné Joven", sortable: false },
                    { key: "customer.birthdate", label: "Fecha de nacimiento", sortable: false },
                    { key: "customer.latest_youth_card.expiration_date", label: "Fecha de caducidad", sortable: false },
                    { key: "shipping_type", label: "Tipo de envío", sortable: false },
                    { key: "card_type", label: "Modalidad", sortable: false },
                    { key: "status", label: "Estado", sortable: false },
                    { key: "was_paid", label: "Pago", sortable: false },
                ],
                formatted_fields: [
                    'created_at',
                    'customer.birthdate',
                    'customer.latest_youth_card.expiration_date',
                    'shipping_type',
                    'status',
                    'was_paid',
                    'card_type',
                    'amount'
                ]
            },
            formSelectedItemId: null,
            showSelectedCardApplicationId: null,
            showSelectedCustomerId: null,
            selectedTemplatePrintType: null,
            selectedTemplatePrintCardApplicationFilters: {},
            selectedTypeForImport: null,
            filters: {
                card_application_custom_filter_id: null,
                name: null,
                years_old: null,
                gender: null,
                identification: null,
                youth_card_code: null,
                email: null,
                reference: null,
                amount: null,
                phone: null,
                card_type: null,
                revised: null,
                paid_status: null,
                shipping_type: null,
                origin_shipping_type: null,
                operation_type: null,
                origin_card_shipping_type_id: null,
                card_shipping_type_id: null,
                status: [],
                province_id: null,
                region_id: null,
                population_id: null,
                payment_date_range: {
                    startDate: null,
                    endDate: null
                },
                birthdate_range: {
                    startDate: null,
                    endDate: null
                },
                created_at_range: {
                    startDate: null,
                    endDate: null
                },
                expiration_date_range: {
                    startDate: null,
                    endDate: null
                },
            }
        };
    },
    methods: {
        showViewModal(item) {
            this.showSelectedCardApplicationId = item?.id || null;
            this.showSelectedCustomerId = item?.customer_id || null;
            this.$bvModal.show(this.showModalId);
        },
        showCreateModal() {
            this.$bvModal.show(this.formModalId);
        },
        showQlkImportModal(type) {
            this.selectedTypeForImport = type
            this.$bvModal.show(this.QlkImportModalId);
        },
        onSuccess(item) {
            this.refreshTable();
            this.showViewModal(item)
        },
        refreshTable() {
            this.$refs.cardApplicationTable.refresh();
        },
        formatter(field, item) {
            switch (field) {
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'card_application_statuses', html: true });
                case 'was_paid':
                    return Status.getStatus({ itemStatus: item?.was_paid, type: 'card_application_paid_status', html: true });
                case 'card_type':
                    return Status.getStatus({ itemStatus: item?.card_type, type: 'youth_card_types', html: true });
                case 'shipping_type':
                    return Status.getStatus({ itemStatus: item?.shipping_type, type: 'shipping_types' });
                default:
                    return '';
            }
        },
        onCreateYouthCardTemplate(type, item) {
            this.refreshTable();
            this.showTemplatePrintModal(type, item);
        },
        async generatePaymentDocuments(filters) {
            const result = await Vue.swal({
                title: "Generar cartas de pago",
                icon: "info",
                html: `¿Está seguro que desea generar las cartas de pago para las solicitudes que coincidan con los filtros aplicados?<br>
                Esta operación no puede ser revertida`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
            });

            if (result.value) {
                await api.post({
                    url: `card-applications/generate-multiple-payment-documents`,
                    data: filters,
                    config: {
                        withLoading: true,
                    },
                });
                this.refreshTable();
                this.showTemplatePrintModal('youth_card_payment', filters);
            }
        },
        async generateYouthCards(filters) {
            const result = await Vue.swal({
                title: "Generar carnés",
                icon: "info",
                html: `¿Está seguro que desea generar los carnés para las solicitudes que coincidan con los filtros aplicados?<br>
                Esta operación no puede ser revertida`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
            });

            if (result.value) {
                await api.post({
                    url: `card-applications/generate-multiple-youth-cards`,
                    data: filters,
                    config: {
                        withLoading: true,
                    },
                });
                this.refreshTable();
                this.showTemplatePrintModal('youth_card', filters);
            }
        },
        generateYouthCardShippings(filters){
            this.showTemplatePrintModal('youth_card_shipping', filters);
        },
        showTemplatePrintModal(type, filters) {
            this.selectedTemplatePrintType = type;
            this.selectedTemplatePrintCardApplicationFilters = filters;
            this.$bvModal.show(this.templatePrintModalId);
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <CardApplicationForm :modal-id="formModalId" @onSuccess="onSuccess"></CardApplicationForm>
                        <QlkImport :modal-id="QlkImportModalId" :type="selectedTypeForImport" @onSuccess="onSuccess">
                        </QlkImport>
                        <customerShow :modal-id="showModalId" :id="showSelectedCustomerId"
                            :card-application-id="showSelectedCardApplicationId"></customerShow>

                        <TemplatePrint :type="selectedTemplatePrintType" :modal-id="templatePrintModalId"
                            :card-application-filters="selectedTemplatePrintCardApplicationFilters"></TemplatePrint>

                        <CustomTable
                            :url="table.url"
                            :fields="table.fields"
                            :formatted-fields="table.formatted_fields"
                            :formatter="formatter"
                            :params="table.params"
                            ref="cardApplicationTable"
                            :show-view="true"
                            :show-edit="false"
                            :showSoftDelete="false"
                            @show="showViewModal"
                            :auto-hide-on-filter="true"
                            :show-modal-id="showModalId" :filters2="filters" :show-input-search="false">
                            <template v-slot:header-actions="{ filters }">
                                <div>
                                    <button class="btn btn-outline-info btn-sm waves-effect waves-light mr-1"
                                        @click="generateYouthCards(filters)">Generar carnés</button>
                                    <button class="btn btn-outline-info btn-sm waves-effect waves-light mr-1"
                                        @click="generatePaymentDocuments(filters)">Generar cartas de pago</button>
                                    <button class="btn btn-outline-info btn-sm waves-effect waves-light mr-3"
                                        @click="generateYouthCardShippings(filters)">Generar cartas de envío</button>
                                    <AddButton @click="showCreateModal"></AddButton>

                                    <!-- <b-dropdown right class="qlk-dropdown-sm ml-2" variant="white" size="sm" title="Más opciones" v-b-tooltip.hover>
                                        <template v-slot:button-content>
                                        <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                                        </template>
<b-dropdown-item @click="showQlkImportModal">Importar solicitudes Ibercaja</b-dropdown-item>
<b-dropdown-item @click="showQlkImportModal('import_payments')">Importar pagos</b-dropdown-item>
</b-dropdown> -->

                                </div>
                            </template>
                            <template v-slot:filters="{ filters }">
                                <div class="row">
                                    <div class="form-group col-12 col-md-6">
                                        <label for="name">Búsqueda de operaciones recientes</label>
                                        <SelectAutocompleteField :url="`/card-application-custom-filters`" :only-ids="true" v-model="filters.card_application_custom_filter_id"></SelectAutocompleteField>
                                    </div>
                                    <div class="form-group col-12 col-md-6" v-if="!filters?.card_application_custom_filter_id">
                                        <label for="name">Revisado</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="revised_status" v-model="filters.revised"></SelectField>
                                    </div>
                                </div>
                                <div class="row" v-if="!filters?.card_application_custom_filter_id">
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="name">Nombre</label>
                                        <input id="name" v-model="filters.name" type="text" class="form-control"
                                            placeholder="Filtrar por nombre" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="identification">Identificación</label>
                                        <input id="identification" v-model="filters.identification" type="text"
                                            class="form-control" placeholder="Filtrar por identificación" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="youth_card_code"># de carné</label>
                                        <input id="youth_card_code" v-model="filters.youth_card_code" type="text"
                                            class="form-control" placeholder="Filtrar por # de carné" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="card_type">Modalidad</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="card_types" v-model="filters.card_type"></SelectField>                                        
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="phone">Fecha de solicitud</label>
                                        <DateRangeField v-model="filters.created_at_range" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="phone">Fecha de pago</label>
                                        <DateRangeField v-model="filters.payment_date_range" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="status">Estado de la solicitud</label>
                                        <SelectField label="label" placeholder="Todos" :multiple="true"
                                            :options="card_application_statuses" v-model="filters.status"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="status">Estado del pago</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="card_application_paid_status" v-model="filters.paid_status"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="phone">Móvil / teléfono</label>
                                        <input id="phone" v-model="filters.phone" type="text" class="form-control"
                                            placeholder="Filtrar por móvil ó teléfono" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="email">Email</label>
                                        <input id="email" v-model="filters.email" type="text" class="form-control"
                                            placeholder="Filtrar por email" />
                                    </div>                                    
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="birthdate_range_picker">Fecha de nacimiento</label>
                                        <DateRangeField v-model="filters.birthdate_range" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="years_old">Por edad</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="years_old_list" v-model="filters.years_old"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="gender">Género</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="genders" v-model="filters.gender"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="expiration_date_range_picker">Fecha de caducidad</label>
                                        <DateRangeField v-model="filters.expiration_date_range" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="reference">Referencia</label>
                                        <input id="reference" v-model="filters.reference" type="text" class="form-control"
                                            placeholder="Filtrar por referencia" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="amount">Importe</label>
                                        <input id="amount" v-model="filters.amount" type="text" class="form-control"
                                            placeholder="Filtrar por importe" />
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="status">Tipo de operación</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="operation_types" v-model="filters.operation_type"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="origin_shipping_type">Tipo de creación</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="shipping_types" v-model="filters.origin_shipping_type"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="name">Oficina de creación</label>
                                        <SelectAutocompleteField
                                            :url="`/card-shipping-types`"
                                            :only-ids="true"
                                            v-model="filters.origin_card_shipping_type_id"
                                            :params="{shipping_type: filters.origin_shipping_type}"
                                        ></SelectAutocompleteField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="shipping_type">Tipo de envío</label>
                                        <SelectField label="label" placeholder="Todos"
                                            :options="shipping_types" v-model="filters.shipping_type"></SelectField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="name">Oficina de envío</label>
                                        <SelectAutocompleteField
                                            :url="`/card-shipping-types`"
                                            :only-ids="true"
                                            v-model="filters.card_shipping_type_id"
                                            :params="{shipping_type: filters.shipping_type}"
                                        ></SelectAutocompleteField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Provincia</label>
                                        <SelectAutocompleteField
                                            :url="`/provinces`"
                                            :only-ids="true"
                                            :multiple="false"
                                            :placeholder="'Busca una provincia'"
                                            :label="'name'"
                                            v-model="filters.province_id"
                                        ></SelectAutocompleteField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Comarca</label>
                                        <SelectAutocompleteField
                                            :url="`/regions`"
                                            :only-ids="true"
                                            :multiple="false"
                                            :placeholder="'Busca una comarca'"
                                            :label="'fullname'"
                                            v-model="filters.region_id"
                                            :params="{province_id: filters.province_id}"
                                        ></SelectAutocompleteField>
                                    </div>
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Población</label>
                                        <SelectAutocompleteField
                                            :url="`/populations`"
                                            :only-ids="true"
                                            :multiple="false"
                                            :placeholder="'Busca una población'"
                                            :label="'fullname'"
                                            v-model="filters.population_id"
                                            :params="{region_id: filters.region_id}"
                                        ></SelectAutocompleteField>
                                    </div>
                                </div>
                            </template>
                            <template #cell-created_at="{ item }">
                                <span v-format-date="item?.created_at"></span>
                            </template>
                            <template #cell-customer.birthdate="{ item }">
                                <span v-format-date="item?.customer?.birthdate"></span>
                            </template>
                            <template #cell-customer.latest_youth_card.expiration_date="{ item }">
                                <span v-format-date="item?.customer?.latest_youth_card?.expiration_date"></span>
                            </template>
                            <template #cell-amount="{ item }">
                                <span>{{ item?.amount | currency }}</span>
                            </template>
                            <template v-slot:after-custom-actions="slotProps">
                                <CardApplicationRevisedButton :cardApplication="slotProps.row?.item"
                                    @onCreate="refreshTable">
                                </CardApplicationRevisedButton>

                                <YouthCardButton :cardApplication="slotProps.row?.item"
                                    @onCreate="onCreateYouthCardTemplate" @print="showTemplatePrintModal">
                                </YouthCardButton>

                                <YouthCardPaymentDocumentButton :cardApplication="slotProps.row?.item"
                                    @onCreate="onCreateYouthCardTemplate" @print="showTemplatePrintModal">
                                </YouthCardPaymentDocumentButton>

                                <YouthCardShippingPrintButton :cardApplication="slotProps.row?.item"
                                    @print="showTemplatePrintModal">
                                </YouthCardShippingPrintButton>

                                <CardApplicationActions :card-application="slotProps.row?.item" @onSuccess="refreshTable"></CardApplicationActions>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>