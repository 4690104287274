<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
// import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";

import {
    identificationTypeComputed,
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        card_application: {
            identification_type_id: '',
            identification_media: null,
            card_application_type_slug: 'new',
            population: null,
            population_id: null,
            province_id: null,
            region_id: null,
            card_shipping_type: null,
            card_shipping_type_id: null,
            origin_card_shipping_type: null,
            origin_card_shipping_type_id: null,
            first_name: null,
            last_name: null,
            identification: null,
            birthdate: null,
            gender: '',
            postcode: null,
            email: null,
            mobile: null,
            phone: null,
            shipping_type: '',
            origin_shipping_type: '',
            allow_commercial_mail: null,
            status: 'processing',
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        SelectAutocompleteField,
        // AddIndividualMediaButton
    },
    computed: {
        ...identificationTypeComputed,
        genders() {
            return Status.getList('genders');
        },
        shippingTypes() {
            return Status.getList('shipping_types');
        }
    },
    validations: {
        card_application: {

            identification_type_id: { required },
            // identification_media: { required },
            card_shipping_type: { 
                required(value) {
                    return this.card_application.shipping_type !== 'online' ? !!value : true;
                }
            },
            origin_card_shipping_type: { 
                required(value) {
                    return this.card_application.origin_shipping_type !== 'online' ? !!value : true;
                }
            },
            first_name: { required },
            last_name: { required },
            identification: { required },
            birthdate: { 
                required,
                // ageRange(value) {
                //     if (!value) return true; // Para permitir que 'required' maneje campos vacíos
                //     const birthDate = new Date(value);
                //     const today = new Date();

                //     // Calcula el rango de fechas permitidas
                //     const minDate = new Date(
                //         today.getFullYear() - 30,
                //         today.getMonth(),
                //         today.getDate()
                //     );
                //     const maxDate = new Date(
                //         today.getFullYear() - 12,
                //         today.getMonth(),
                //         today.getDate()
                //     );

                //     // Verifica si la fecha ingresada está dentro del rango permitido
                //     return birthDate >= minDate && birthDate <= maxDate;
                // } 
            },
            gender: { required },
            postcode: {  },
            population: {  },
            address: {  },
            email: {  },
            mobile: {  },
            phone: {  },
            shipping_type: { required },
            origin_shipping_type: { required },
            status: { required },
        }
    },
    methods: {
        async shown() {
            this.$store.dispatch("identificationType/loadList");
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.card_application;
            // data.identification_media_id = this.card_application?.identification_media?.id;
            data.population_id = this.card_application?.population?.id;
            data.card_shipping_type_id = this.card_application?.card_shipping_type?.id;
            data.origin_card_shipping_type_id = this.card_application?.origin_card_shipping_type?.id;
            data.is_local_form = 1;
            const response = await api.save({
                url: 'card-applications',
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        changeShippingType() {
            this.card_application.card_shipping_type_id = '';
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de solicitud'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="first_name">Nombre</label>
                                <input id="first_name" v-model="card_application.first_name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.card_application.first_name.$error }" @blur="$v.card_application.first_name.$touch()"/>
                                <div v-if="!$v.card_application.first_name.required" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="last_name">Apellido</label>
                                <input id="last_name" v-model="card_application.last_name" type="text" class="form-control" placeholder="Ingresa un apellido"
                                    :class="{ 'is-invalid': $v.card_application.last_name.$error }" @blur="$v.card_application.last_name.$touch()"/>
                                <div v-if="!$v.card_application.last_name.required" class="invalid-feedback">Apellido es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="birthdate">Fecha de nacimiento</label>
                                <input id="birthdate" v-model="card_application.birthdate" type="date" class="form-control"
                                    :class="{ 'is-invalid': $v.card_application.birthdate.$error }" @blur="$v.card_application.birthdate.$touch()"/>
                                <div v-if="!$v.card_application.birthdate.required" class="invalid-feedback">Fecha de nacimiento es requerida</div>
                                <div v-if="!$v.card_application.birthdate.ageRange" class="invalid-feedback">La fecha debe ser de alguien entre 12 y 30 años.</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="identification_type_id">Tipo de identificación</label>
                                <select v-model="card_application.identification_type_id" class="form-control"
                                    :class="{ 'is-invalid': $v.card_application.identification_type_id.$error }" @blur="$v.card_application.identification_type_id.$touch()">
                                    <option value="">Ninguna</option>
                                    <option v-for="option in identificationTypes" v-bind:value="option.id" :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <div v-if="!$v.card_application.identification_type_id.required" class="invalid-feedback">Tipo de identificación es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="identification">Identificación</label>
                                <input id="identification" v-model="card_application.identification" type="text" class="form-control"
                                    :class="{ 'is-invalid': $v.card_application.identification.$error }" @blur="$v.card_application.identification.$touch()"/>
                                <div v-if="!$v.card_application.identification.required" class="invalid-feedback">Identificación es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="gender">Género</label>
                                <select v-model="card_application.gender" class="form-control"
                                    :class="{ 'is-invalid': $v.card_application.gender.$error }" @blur="$v.card_application.gender.$touch()">
                                    <option value="">Ninguno</option>
                                    <option v-for="option in genders" v-bind:value="option.id" :key="option.id">
                                        {{ option.label }}
                                    </option>
                                </select>
                                <div v-if="!$v.card_application.gender.required" class="invalid-feedback">Género es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="allow_commercial_mail">Permite envío de correos comerciales</label>
                                <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="card_application.allow_commercial_mail"
                                        class="custom-control-input"
                                        id="allow_commercial_mail"
                                    />
                                    <label class="custom-control-label" for="allow_commercial_mail">Sí</label>
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <label>Adjunto documento de identificación</label>
                                <AddIndividualMediaButton v-model="card_application.identification_media"></AddIndividualMediaButton>
                                <div v-if="!$v.card_application.identification_media.required && submitted" class="invalid-feedback d-block">Adjunto documento de identificación es requerido</div>
                            </div> -->
                        </div>                         
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de Dirección
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="address">Dirección</label>
                                <input id="address" v-model="card_application.address" type="text" class="form-control" placeholder="Ingresa una dirección"
                                    :class="{ 'is-invalid': $v.card_application.address.$error}" @blur="$v.card_application.address.$touch()"/>
                                <div v-if="!$v.card_application.address.required" class="invalid-feedback">Dirección es requerida</div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="postcode">Código postal</label>
                                <input id="postcode" v-model="card_application.postcode" type="text" class="form-control" placeholder="Ingresa un código postal"
                                    :class="{ 'is-invalid': $v.card_application.postcode.$error }" @blur="$v.card_application.postcode.$touch()"/>
                                <div v-if="!$v.card_application.postcode.required" class="invalid-feedback">Código postal es requerido</div>
                            </div>
                            <div class="form-group col-lg-12">
                                <label>Población</label>
                                <SelectAutocompleteField
                                    :url="`/populations`"
                                    :multiple="false"
                                    :placeholder="'Busca una población'"
                                    :label="'fullname'"
                                    v-model="card_application.population"
                                    :class="{ 'is-invalid': $v.card_application.population.$error }"
                                ></SelectAutocompleteField>
                                <div v-if="!$v.card_application.population.required" class="invalid-feedback">Población es requerida</div>
                            </div>
                        </div>                    
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de contacto
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="email">Correo electrónico</label>
                                <input id="email" v-model="card_application.email" type="text" class="form-control" placeholder="Ingresa un correo electrónico"
                                    :class="{ 'is-invalid': $v.card_application.email.$error }" @blur="$v.card_application.email.$touch()"/>
                                <div v-if="!$v.card_application.email.required" class="invalid-feedback">Correo electrónico</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="mobile">Teléfono Móvil</label>
                                <input id="mobile" v-model="card_application.mobile" type="text" class="form-control" placeholder="Ingresa un teléfono móvil"
                                    :class="{ 'is-invalid': $v.card_application.mobile.$error }" @blur="$v.card_application.mobile.$touch()"/>
                                <div v-if="!$v.card_application.mobile.required" class="invalid-feedback">Teléfono Móvil es requerido</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="phone">Teléfono</label>
                                <input id="phone" v-model="card_application.phone" type="text" class="form-control" placeholder="Ingresa un teléfono"
                                    :class="{ 'is-invalid': $v.card_application.phone.$error }" @blur="$v.card_application.phone.$touch()"/>
                                <div v-if="!$v.card_application.phone.required" class="invalid-feedback">Teléfono es requerido</div>
                            </div>
                        </div>                   
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de creación
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="origin_shipping_type">Tipo de origen</label>
                                <select v-model="card_application.origin_shipping_type" class="form-control"
                                    :class="{ 'is-invalid': $v.card_application.origin_shipping_type.$error }" @blur="$v.card_application.origin_shipping_type.$touch()">
                                    <option value="">Ninguno</option>
                                    <option v-for="option in shippingTypes" v-bind:value="option.id" :key="option.id">
                                        {{ option.description }}
                                    </option>
                                </select>
                                <div v-if="!$v.card_application.shipping_type.required" class="invalid-feedback">Tipo de origen es requerido</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6" v-if="card_application.origin_shipping_type != '' && card_application.origin_shipping_type != 'online'">
                                <label>Oficina</label>
                                <SelectAutocompleteField
                                    :url="`/card-shipping-types`"
                                    :multiple="false"
                                    :placeholder="'Busca una oficina'"
                                    v-model="card_application.origin_card_shipping_type"
                                    :params="{shipping_type: card_application.origin_shipping_type}"
                                    :class="{ 'is-invalid': $v.card_application.origin_card_shipping_type.$error }"
                                ></SelectAutocompleteField>
                                <div v-if="!$v.card_application.origin_card_shipping_type.required" class="invalid-feedback">Oficina es requerida</div>
                            </div>
                        </div>                   
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de recogida
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="shipping_type">Tipo de envío</label>
                                <select v-model="card_application.shipping_type" class="form-control"
                                    :class="{ 'is-invalid': $v.card_application.shipping_type.$error }" @blur="$v.card_application.shipping_type.$touch()">
                                    <option value="">Ninguno</option>
                                    <option v-for="option in shippingTypes" v-bind:value="option.id" :key="option.id">
                                        {{ option.description }}
                                    </option>
                                </select>
                                <div v-if="!$v.card_application.shipping_type.required" class="invalid-feedback">Tipo de envío es requerido</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6" v-if="card_application.shipping_type != '' && card_application.shipping_type != 'online'">
                                <label>Oficina</label>
                                <SelectAutocompleteField
                                    :url="`/card-shipping-types`"
                                    :multiple="false"
                                    :placeholder="'Busca una oficina'"
                                    v-model="card_application.card_shipping_type"
                                    :params="{shipping_type: card_application.shipping_type}"
                                    :class="{ 'is-invalid': $v.card_application.card_shipping_type.$error }"
                                ></SelectAutocompleteField>
                                <div v-if="!$v.card_application.card_shipping_type.required" class="invalid-feedback">Oficina es requerida</div>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>